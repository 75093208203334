import type { GetServerSideProps } from 'next';
import Error from 'next/error';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getCurrentProgrammeId } from '@/utils/programme';
import { sharedTranslationNamespaces } from '@/utils/constants';
import { getCurrentUser } from '@/utils/user';
import { buildDataLayerInfo } from '@/utils/dataLayer';

type ErrorPageProps = {
  statusCode: number;
};

const ErrorPage = ({ statusCode }: ErrorPageProps) => (
  <Error statusCode={statusCode} />
);

export const getServerSideProps: GetServerSideProps = async context => {
  const programmeId = getCurrentProgrammeId(context.req);

  const user = await getCurrentUser(context.req, context.res, {
    isLoggedInAction: false,
  });

  return {
    props: {
      programmeId,
      dataLayerInfo: buildDataLayerInfo({ user, programmeId }),
      statusCode: context.res?.statusCode || 500,
      ...(await serverSideTranslations(
        context.locale ?? 'en',
        sharedTranslationNamespaces,
      )),
    },
  };
};

export default ErrorPage;
